import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { changeContext } from "../../../actions/context";
import CryptoJS from 'crypto-js';

import Title from "../../../components/Title";
import PaperServiceProposal from "../../../components/Paper/ServiceProposals";

import './styles.css'
import InputText from '../../../components/Input/Text';
import Button from '../../../components/Button';
import { createPayments, getCards } from '../../../services/payments';
import PaperCreditCard from '../../../components/Paper/CreditCard';
import { recreateSolicitation } from '../../../actions/solicitations';
import { changeSeriveProposal, insertServiceProposals } from '../../../actions/serviceProposals';
import CustomAlert from '../../../components/Alert';
import { getAddress } from '../../../services/cep';
import *  as event from '../../../services/event'

const PaymentsCheckout = () => {
    const dispatch = useDispatch()

    const user = useSelector(state => state.user)
    const lead = useSelector(state => state.lead)
    const contextControler = useSelector(state => state.context)
    const solicitation = useSelector(state => state.solicitation)
    const servicesState = useSelector(state => state.services.auto)
    const serviceProposals = useSelector(state => state.serviceProposals.selected)

    const [loadingButton, setLoadingButton] = useState(false)
    const [addNewCard, setAddNewCard] = useState(false)
    const [cards, setCards] = useState([])
    const [cardSelected, setCardSelected] = useState()
    const [paymentMethodSelected, setPaymentMethod] = useState()
    const [paymentPlaceSelected, setPaymentPlace] = useState("online")
    const [documentTypeSelected, setDocumentType] = useState()
    const [creditCard, setCreditCard] = useState({
        number: "",
        expires: "",
        cvc: "",
        holder: "",
    })
    const [customer, setCustomer] = useState({
        document: "",
        address: {
            country: "BR",
            state: "",
            city: "",
            zip_code: "",
            line_1: "",
        }
    })

    const [error, setError] = useState({
        payment: {error: false, message: "Falha ao efetuar transação."}
    })

    const services = {
        "mecanico": 'Mecânico',
        "bateria": 'Socorro Bateria',
        "chaveiro": 'Chaveiro',
        "combustivel": 'Combustível',
        "pneu": 'Troca de Pneus',
    }

    const paymentMethods = [
        {id: "credit_card", label: "Cartão de crédito"},
        {id: "pix", label: "Pix"},
    ]

    const paymentPlace = [
        {id: "onsite", label: "No local"},
        {id: "online", label: "Online"},
    ]

    const documentType = [
        {id: "documentPF", label: "Pessoa Física"},
        {id: "documentPJ", label: "Pessoa Jurídica"},
    ]

    const handleGoBack = () => {
        dispatch(changeContext({
            active: "solicitation",
            step: "maps",
            action: ""
        }))
    }

    const selectPaymentMethod = (paymentMethod) => {
        setPaymentMethod(paymentMethod)
    }

    const selectPaymentPlace = (paymentPlace) => {
        setPaymentPlace(paymentPlace)
        setPaymentMethod("pix")
    }

    const random16String = () => {
        let result = '';
        for (let i = 0; i < 16; i++) {
            result += Math.floor(Math.random() * 10);
        }
        return String(result);
    }

    const encryptedCard = (card) => {
        const derived_key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEPPER_SECRET_KEY)
        const randomInt16 = random16String()
        const iv = CryptoJS.enc.Utf8.parse(randomInt16);
        
        const cipherText = CryptoJS.AES.encrypt(JSON.stringify(card), derived_key, {
            iv: iv,
            mode: CryptoJS.mode.CBC
        }).toString()

        return randomInt16 + cipherText
    }

    const checkNewCreditCard = () => {
        if (cardSelected) return true
        else if (
            paymentMethodSelected === "pix" && paymentPlaceSelected === "online" && (
                ![14, 18].includes(customer.document.length)
            )
        ) return false
        else if (
            paymentMethodSelected !== "pix" && (
                creditCard.number === "" || 
                creditCard.expires === "" || 
                creditCard.cvc === "" || 
                creditCard.holder === "" || 
                ![14, 18].includes(customer.document.length) ||
                customer.address.zip_code === "" ||
                customer.address.state === "" ||
                customer.address.city === "" ||
                customer.address.line_1 === "" 
            )
        ) return false
        return true
    }

    const gtagReportConversion = (value) => {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-11179585816/dIO4CLWw-5QZEJji69Ip',
            'value': value,
            'currency': 'BRL',
            'transaction_id': '',
        })
    }

    const hireService = async () => {
        setLoadingButton(true)

        const payload = {
            serviceProposal: serviceProposals,
            userType: contextControler.action,
            paymentMethod: paymentMethodSelected,
            paymentPlace: paymentPlaceSelected,
            customer: customer,
            solicitation: solicitation,
            difficulty: solicitation.difficulty,
            lead: lead,
        }

        customer.cellphone = solicitation.cellphone
        if (paymentMethodSelected === "pix" && checkNewCreditCard()) {
            const response = await createPayments(payload)
            
            if (response) {
                dispatch(recreateSolicitation(response.solicitation))

                dispatch(changeSeriveProposal({
                    column: "status",
                    value: "approved"
                }))
                
                if (paymentPlaceSelected === "online") {
                    dispatch(changeContext({
                        step: "pix",
                        active: "payments",
                        action: {
                            qrcode: response.qrcode,
                            userType: contextControler.action
                        }
                    }))
                } else {
                    gtagReportConversion(solicitation.fee ? solicitation.fee : solicitation.price * 0.25)

                    dispatch(changeContext({
                        step: "maps",
                        active: "solicitation",
                        action: "",
                    }))
                }
            }

            event.registerEvent({
                type: "createPayment",
                uuidLead: lead.uuid,
                uuidUser: user.uuid,
                paymentMethod: "pix"
            })
        } else if (paymentMethodSelected !== "pix" && checkNewCreditCard()) {
            const cardEncrypted = !cardSelected ? encryptedCard({data: creditCard, type: "new"}) : encryptedCard({data: cardSelected, type: "old"})
            
            payload.card = cardEncrypted
            const response = await createPayments(payload)
            
            if (response) {
                dispatch(recreateSolicitation(response.solicitation))
                
                dispatch(insertServiceProposals(response.serviceProposals))

                dispatch(changeSeriveProposal({
                    column: "status",
                    value: "approved"
                }))             
                
                dispatch(changeContext({
                    active: "payments",
                    step: "success",
                    action: ""
                }))

                event.registerEvent({
                    type: "createPayment",
                    uuidLead: lead.uuid,
                    uuidUser: user.uuid,
                    paymentMethod: "credit_card"
                })
            } else {
                setError({...error, payment: {...error.payment, error: true}})
            }
        } else {
            setError({...error, payment: {...error.payment, error: true}})
        }
        
        setLoadingButton(false)
    }

    const handleCreditCard = (column, value) => {
        setCreditCard({
            ...creditCard,
            [column]: value
        })
    }

    const handleCustomer = (column, value) => {
        setCustomer({
            ...customer,
            [column]: value
        })
    }

    const handleCustomerAddress = (column, value) => {
        setCustomer({
            ...customer,
            address: {
                ...customer.address,
                [column]: value
            }
        })
    }

    const handleGetPaymentMethods = async () => {
        const response = await getCards(user.uuid)
        setCards(response)
    }

    const hanleAddNewcard = () => {
        setCardSelected()
        setAddNewCard(true)
    }

    const handleCreditcardSelected = (creditCard) => {
        setCardSelected(creditCard)
        setAddNewCard(false)
    }

    const hanldeGetAddress = async () => {
        if (customer.address.zip_code !== "" && customer.address.zip_code.length === 10) {
            const zipCode = customer.address.zip_code.replace(/[^0-9]/g, '')
            const response = await getAddress(zipCode)
            
            if (response.status === 200) {
                const address = response.data

                setCustomer({
                    ...customer,
                    address: {
                        ...customer.address,
                        state: address.uf,
                        city: address.localidade,
                        line_1: address.logradouro
                    }
                })
            }
        }
    }

    useEffect(() => {
        handleGetPaymentMethods()
    }, [])

    useEffect(() => {
        event.registerEvent({
            type: "openedCheckout",
            uuidLead: lead.uuid,
            uuidUser: user.uuid
        })
    }, [])

    return (
        <>
            <Title label="Contratação de Serviço" goBack={handleGoBack}/>
            <div className="checkout-header">
                <PaperServiceProposal checkout serviceProposal={serviceProposals}/>
            </div>
            <div className='checkout-content'>
                <div className="checkout-payment-methods-content">
                    {paymentPlaceSelected === "online" ? (
                        <>
                            <span>Selecione a forma de pagamento</span>
                            <div className="checkout-payment-methods-button-group">
                                {paymentMethods.map((item, index) => {
                                    return (
                                        <button 
                                            key={index}
                                            className={`checkout-payment-methods-button-item ${paymentMethodSelected === item.id ? "active" : "inative"}`}
                                            onClick={() => selectPaymentMethod(item.id)}
                                        >
                                            {item.label}
                                        </button>
                                    )
                                })}
                            </div>
                        </>
                    ) : paymentPlaceSelected === "onsite" && (
                        <div className="checkout-describe">
                            <span>A modalidade de pagamento no local está disponível <strong>exclusivamente via PIX</strong>, devendo ser efetuado diretamente ao prestador de serviço.</span>
                        </div>
                    )}
                    {paymentMethodSelected === "credit_card" &&
                        <div className="checkout-payment-methods">
                            <div className="checkout-describe">
                                <span>
                                    Para podermos enviar o prestador até sua localização, é necessário realizar a reserva do valor do serviço. <br /> <br />
                                    <strong>
                                        Mas, fique tranquilo! A cobrança só será efetuada ao término do atendimento, após a resolução do seu problema.
                                    </strong>
                                </span>
                            </div>
                            <div className='checkout-payment-last-cred-cards'>
                                <span>Últimos cartões utilizados:</span>
                                {cards.map((item, index) => {
                                    return (
                                        <PaperCreditCard 
                                            key={index} 
                                            card={item}
                                            onSelected={() => handleCreditcardSelected(item)}
                                            active={cardSelected && cardSelected.uuid === item.uuid}
                                        />
                                    )
                                })}
                            </div>
                            <button 
                                className='checkout-add-card-button'
                                onClick={hanleAddNewcard}
                            >
                                Adicionar novo cartão
                            </button>
                        </div>
                    }
                </div>
                {paymentMethodSelected === "credit_card" && addNewCard && 
                    <div className={`checkout-forms-content ${paymentMethodSelected === "pix" && "disabled"}`}>
                        <span>Dados do cartão</span>
                        <div className='checkout-input-content'>
                            <InputText 
                                mask="card"
                                label="Número do cartão"
                                onChange={(event) => handleCreditCard('number', event.target.value)}
                            />
                            <div className='checkout-input-group'>
                                <InputText 
                                    mask="expcard"
                                    label="Validade"
                                    onChange={(event) => handleCreditCard('expires', event.target.value)}
                                />
                                <InputText 
                                    label="CVC"
                                    type="number"
                                    onChange={(event) => handleCreditCard('cvc', event.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <span>Dados do titular</span>
                            <InputText 
                                label="Nome do titular do cartão"
                                onChange={(event) => handleCreditCard('holder', event.target.value)}
                                style={{marginTop: 6}}
                            />
                            <div className='checkout-document-type'>
                                {documentType.map((item, index) => {
                                    return (
                                        <button 
                                            key={item.id}
                                            onClick={() => setDocumentType(item.id)}
                                            className={`checkout-payment-methods-button ${item.id === documentTypeSelected && "active"}`}
                                        >
                                            {item.label}
                                        </button>
                                    )
                                })}
                            </div>
                            {documentTypeSelected &&
                                <InputText 
                                    mask={documentTypeSelected}
                                    label={documentTypeSelected === "documentPF" ? "Digite CPF do titular" : "Digite CNPJ do titular"}
                                    onChange={(event) => handleCustomer('document', event.target.value)}
                                    style={{marginTop: 8}}
                                />
                            }
                            <div className='checkout-input-group'>
                                <InputText 
                                    label="CEP"
                                    mask="cep"
                                    value={customer.address.zip_code}
                                    onChange={(event) => handleCustomerAddress('zip_code', event.target.value)}
                                    onBlur={hanldeGetAddress}
                                />
                            </div>
                            <InputText 
                                label="Logradouro"
                                value={customer.address.line_1}
                                onChange={(event) => handleCustomerAddress('line_1', event.target.value)}
                                style={{marginTop: 8}}
                            />
                            <div className='checkout-input-group'>
                                <InputText 
                                    label="Cidade"
                                    value={customer.address.city}
                                    onChange={(event) => handleCustomerAddress('city', event.target.value)}
                                />
                                <InputText 
                                    label="Estado"
                                    value={customer.address.state}
                                    onChange={(event) => handleCustomerAddress('state', event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                }
                {paymentMethodSelected === "pix" && paymentPlaceSelected === "online" &&
                    <div className="checkout-forms-content" style={{marginTop: 8}}>
                        <div className="checkout-describe">
                            <span>
                                O pagamento via PIX deve ser realizado antes de enviarmos o prestador até sua localização. <br /> <br />
                                <strong>
                                    Mas, fique tranquilo! Em situações onde ocorram imprevistos na execução do serviço, garantimos o reembolso do valor transferido via PIX, conforme as regras de cancelamento estabelecidas.
                                </strong>
                            </span>
                        </div>
                        <div className='checkout-payment-pix'>
                            <span>Dados do pagador</span>
                            <div className='checkout-document-type'>
                                {documentType.map((item, index) => {
                                    return (
                                        <button 
                                            key={item.id}
                                            onClick={() => setDocumentType(item.id)}
                                            className={`checkout-payment-methods-button ${item.id === documentTypeSelected && "active"}`}
                                        >
                                            {item.label}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                        {documentTypeSelected &&
                            <InputText 
                                mask={documentTypeSelected}
                                label={documentTypeSelected === "documentPF" ? "Digite CPF do pagador" : "Digite CNPJ do pagador"}
                                onChange={(event) => handleCustomer('document', event.target.value)}
                                style={{marginTop: 8}}
                            />
                        }
                    </div>
                }
                <CustomAlert
                    show={error.payment.error}
                    message={error.payment.message}
                    onClose={() => setError({...error, payment: {...error.payment, error: false}})}
                />
                <div className='checkout-button-content'>
                    <Button 
                        disabled={!checkNewCreditCard()}
                        loading={loadingButton}
                        label="Contratar serviço"
                        onClick={hireService}
                    />
                </div>
            </div>
        </>
    )
}
export default PaymentsCheckout;